@import "../../assets/styles/variables.scss";

.imageGallery {
    &_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-left: 0%;
        flex-wrap: wrap;
        img {
            width: 100%;
            height: auto;
            margin: 2vh 0;
        }
    }

}
