@import 'assets/styles/variables.scss';

.if-navbar {
  &_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 959px) {
      justify-content: space-between;
      margin: 0% 2%;
    }
    @media only screen and (min-width: 960px) {
      justify-content: center;
    }

    &_logo {
      width: 4rem;
      height: 4rem;
      background-color: white;
      -webkit-mask: url(/assets/svg/if-logo.svg) no-repeat center;
      mask: url(/assets/svg/if-logo.svg) no-repeat center;
    }
    .active {
      color: $MainColor4;
      border-bottom: 2px solid $MainColor4;
      margin-bottom: -2px;
    }
    button:focus {
      outline: 0;
    }

    .activeStage button {
      background: none !important;
      color: $MainColor4;
      font-weight: normal;
      border: 2px solid $MainColor4;
    }
    .expanded .activeStage button {
      background: none !important;
      color: $MainColor7;
      font-weight: normal;
      border: 2px solid $MainColor7;
    }

    &_item {
      text-decoration: none;
      list-style: none;
      cursor: pointer;
      color: white;
      padding: 10px;
      .active {
        border-bottom: 2px solid $MainColor4;
        margin-bottom: -2px;
      }
    }
    &_links {
      flex-direction: row;
      justify-content: space-between;
      width: 600px;
      margin: 0% 70px;
      @media only screen and (max-width: 959px) {
        display: none;
      }
      @media only screen and (min-width: 960px) {
        display: flex;
      }
    }

    &_button {
      background-color: $MainColor4;
      text-transform: uppercase;
      border-style: none;
      color: $MainColor7;
      padding: 5px 15px;
      margin-top: auto;
      margin-bottom: auto;
    }
  

  }
}
.burger-menu {
  background-image: url(/assets/png/burger-menu.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparentize($color: #00000000, $amount: 0);
  width: 35px;
  height: 35px;
  margin: 20px;
}
.noscroll {
  overflow-y: hidden;
}

.navigation {
  width: 100%;
  z-index: 1;
}
.expanded {
  height: 100vh;
  background-color: white;
  .burger-menu {
    background-image: url(/assets/png/burger-menu-inverted.png);
  }
}

.expanded > .if-navbar_link_list {
  display: flex;
  flex-direction: column;
  margin: 15px calc(100vw / 5.5);
  justify-content: space-evenly;
  height: calc(100vh - 100px - 30px);
}

.expanded > .if-navbar_link_list .if-navbar_container_item {
  color: $MainColor7;
  padding: 5px;
  font-size: 24px;
  padding-left: 20px;
}

.expanded > .if-navbar_link_list .if-navbar_container_item.active {
  color: $MainColor7;
  border-left: 2px solid $MainColor7;
  margin-left: -2px;
  font-weight: bold;
}

.hide {
  display: none;
}
.mobile {
  @media only screen and (min-width: 960px) {
    display: none;
  }
}
