@import '../../assets/styles/variables.scss'; 

.Privacy_container {
    // background-color: white;
    margin: 10vh 0;
    width: 100%;
    p {
        color: white;
    }
    h2, h3 {
        color: white;
    }
    a {
        color: $MainColor4;
    }
}
