@import "assets/styles/variables.scss";

.if-hero-space {
  &_root {
    width: 100%;
    @media only screen and (max-width: 600px) {
      height: 100vh;
      max-height: 612px;
    }
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &_image {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 16%;
    z-index: -1;
  }
  &_container {
    padding-top: 70px;
    @media only screen and (min-width: 600px) {
      padding-bottom: 70px;
    }
    overflow: hidden;
    max-height: 100vh;
    position: relative;
  }
  &_text {
    position: relative;
    @media only screen and (max-width: 600px) {
      margin-top: 45%;
    }
    @media only screen and (max-width: 960px) {
      max-width: 90%;
    }
    @media only screen and (min-width: 960px) {
      max-width: 50%;
    }
    &_h2 {
      color: rgba($color: #ffffff, $alpha: 0.2);
      font-size: 42px;
    }
    &_h1 {
      font-family: "LouisGeorgeCafe";
      font-size: 3.5rem;
      line-height: 3.75rem;
      color: $MainColor4;
      margin-bottom: 2rem;
      @media only screen and (min-width: 960px) {
        font-size: 7.5rem;
        line-height: 8.125rem;
      }
    }
    &_subtitle {
      color: rgba($color: #ffffff, $alpha: 1);
    }
    &_arrow-down {
      position: absolute;
      bottom: 70px;
      right: 15px;
    }
  }
  &_right {
    @media only screen and (min-width: 960px) {
      left: 50%;
    }
  }
  &_transparent {
    opacity: 0;
  }
  &_background {
    &_light {
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      padding: 8px;
    }
    &_dark {
      background-color: rgba($color: #000000, $alpha: 0.8);
      padding: 8px;
    }
  }

  // &_image {
  //   position: absolute;
  //   width: 100%;
  //   height: auto;
  //   z-index: -1;
  // }
  &_desktopOnly {
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }
  &_mobileOnly {
    @media only screen and (min-width: 960px) {
      display: none;
    }
  }
}
