@import "assets/styles/variables.scss";
.project-grid {
	//justify-content: center;
	display: grid;
	grid-template-columns: repeat(auto-fit);
	grid-template-rows: repeat(auto);
	column-gap: 2%;
	row-gap: 20px;
	background-color: $MainColor3;
	margin-bottom: 23vw;
}

/*Boxen*/
.box {
	justify-content: center;
	background-size: contain;
	height: 18vw;
	width: 26vw;
	background-color: #3620ad;

	display: block;
	padding: 1vw;
	font-size: 1.5vw;
	text-align: center;
	color: #3620ad;
	word-wrap: break-word;
}
.box:hover {
	background-image: none;
	background-color: $MainColor4;
	box-shadow: 1vw 1vw rgb(106, 87, 206);
}
.box:hover:before {
	display: block;
	margin: 1vw;
	padding: 1vw;
	font-size: 1.5vw;
	margin-top: 10%;
	text-align: center;
	color: #3620ad;

	word-wrap: break-word;
}

/*Spalten*/
.column-left {
	grid-column: 1 / 2;
}
.column-center {
	grid-column: 2 / 3;
	margin-top: 3vh;
}
.column-right {
	grid-column: 3 / 4;
	margin-top: 1.5vh;
}
@media screen and (max-width: 960px) {

	.column-left {
		grid-column: 1 / 4;
	}
	.column-center {
		grid-column: 1 / 4;
		margin-top: 0;
	}
	.column-right {
		grid-column: 1 / 4;
	}
	body {
		grid-template-rows: repeat(30, 300px);
		justify-content: center;
	}
	.row-small {
		grid-row: auto;
	}
	.row-1-left {
		grid-row: auto;
	}
	.row-2-left {
		grid-row: auto;
	}
	.row-3-left {
		grid-row: auto;
	}
	.row-4-left {
		grid-row: auto;
	}
	.row-5-left {
		grid-row: auto;
	}
	.row-6-left {
		grid-row: auto;
	}
	.row-7-left {
		grid-row: auto;
	}
	.row-8-left {
		grid-row: auto;
	}
	.row-9-left {
		grid-row: auto;
	}
	.row-1-right {
		grid-row: auto;
	}
	.row-2-right {
		grid-row: auto;
	}
	.row-3-right {
		grid-row: auto;
	}
	.row-4-right {
		grid-row: auto;
	}
	.row-5-right {
		grid-row: auto;
	}
	.row-6-right {
		grid-row: auto;
	}
	.row-7-right {
		grid-row: auto;
	}
	.row-8-right {
		grid-row: auto;
	}
	.row-9-right {
		grid-row: auto;
	}

	/*mitte*/
	.row-1-center {
		grid-row: auto;
	}
	.row-2-center {
		grid-row: auto;
	}
	.row-3-center {
		grid-row: auto;
	}
	.row-4-center {
		grid-row: auto;
	}
	.row-5-center {
		grid-row: auto;
	}
	.row-6-center {
		grid-row: auto;
	}
	.row-7-center {
		grid-row: auto;
	}
	.row-8-center {
		grid-row: auto;
	}
	.row-9-center {
		grid-row: auto;
	}
}

/*Reihen*/
/*Links und rechts*/
.row-1-left {
	content: "Projekttitel jhdjyHdlas lisajdkasj lkskjdlkajsdlkjaskl";
	grid-row: 1/3;
}
.row-2-left {
	grid-row: 3/5;
}
.row-3-left {
	grid-row: 5/7;
}
.row-4-left {
	grid-row: 7/9;
}
.row-5-left {
	grid-row: 9/11;
}
.row-6-left {
	grid-row: 11/13;
}
.row-7-left {
	grid-row: 13/15;
}
.row-8-left {
	grid-row: 15/17;
}
.row-9-left {
	grid-row: 17/19;
}
.row-1-right {
	grid-row: 1/3;
}
.row-2-right {
	grid-row: 3/5;
}
.row-3-right {
	grid-row: 5/7;
}
.row-4-right {
	grid-row: 7/9;
}
.row-5-right {
	grid-row: 9/11;
}
.row-6-right {
	grid-row: 11/13;
}
.row-7-right {
	grid-row: 13/15;
}
.row-8-right {
	grid-row: 15/17;
}
.row-9-right {
	grid-row: 17/19;
}

/*mitte*/
.row-1-center {
	grid-row: 2/4;
}
.row-2-center {
	grid-row: 4/6;
}
.row-3-center {
	grid-row: 6/8;
}
.row-4-center {
	grid-row: 8/10;
}
.row-5-center {
	grid-row: 10/12;
}
.row-6-center {
	grid-row: 12/14;
}
.row-7-center {
	grid-row: 14/16;
}
.row-8-center {
	grid-row: 16/18;
}
.row-9-center {
	grid-row: 18/20;
}
