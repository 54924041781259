@import "assets/styles/variables.scss";

.bigLogo {
  transform: scale(1.7);
}

.footer {
  background-color: #fff;
  margin-top: 15vh;

  width: 100%;
  &_sponsors-heading {
    padding-top: 0.5rem;
    color: #f6acea;
    text-align: center;
    h2:nth-child(1) {
      margin-bottom: 0px !important;
    }
    h2:nth-child(2) {
      color: $MainColor3;
      margin: 0px !important;
    }
  }
  &_sponsors-content {
    padding-top: 0.5rem;
    justify-content: space-around;
    flex-direction: row;
    padding-bottom: 2rem;
    &_sponsor {
      display: flex;
      justify-content: center;
      height: 7rem;
      img {
        height: 100%;
        width: 70%;
      }
      &.big {
        height: 10rem;
      }
    }
  }

  &_real {
    width: 100% !important;
    margin: 0px !important;
    background: $MainColor3;
    span {
      display: none;
    }
    &_information {
      height: 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_site {
        text-decoration: none;
        color: white;
      }
    }
    &_social {
      height: 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 10px;
      }
      &_link {
        text-decoration: none;
        color: white;
        margin-left: 2rem;
        display: flex;
        align-items: center;
      }
      &_link:nth-child(1) {
        margin-left: 0px;
        margin-right: auto;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (min-width: 960px) {
  .footer {
    &_sponsors-heading {
      text-align: left;
    }
    &_real {
      span {
        display: block;
      }
      &_information {
        justify-content: flex-start;
        &_site {
          padding-right: 2rem;
        }
      }
      &_social {
        justify-content: flex-end;
        &_link {
          padding-left: 2rem;
        }
      }
    }
    &_remove {
      display: none;
    }
  }
}
