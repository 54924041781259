.home-right {
  float: right;
}
.home-speaker-textbox {
  width: 80%;
  height: 100%;
  padding: 20px 50px;
}
.home-centered-container {
  width: 100%;
}
.home-centered-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
