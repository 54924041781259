@font-face {
  font-family: LouisGeorgeCafe;
  src: url("../../fonts/Louis George Cafe Bold.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

h1,
.h1 {
  font-family: "LouisGeorgeCafe";
  font-size: 3.5rem;
  line-height: 3.75rem;
  color: $MainColor4;
  margin-bottom: 2rem;
  @media only screen and (min-width: 960px) {
    font-size: 7.5rem;
    line-height: 8.125rem;
  }
}

h2,
.h2 {
  font-family: "LouisGeorgeCafe";
  font-size: 1.625rem;
  line-height: 2.25rem;
  margin: 1rem 0 2rem;
  @media only screen and (min-width: 960px) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

h3,
.h3 {
  font-family: "LouisGeorgeCafe";
  font-size: 1.175rem;
  line-height: 2rem;
  margin: 1rem 0 2rem;
  @media only screen and (min-width: 960px) {
    font-size: 1.625rem;
    line-height: 2.5rem;
  }
}

h4,
.h4 {
  font-family: "LouisGeorgeCafe";
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 1rem 0 1rem;
  @media only screen and (min-width: 960px) {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }
}

.intro-subtitle {
  opacity: 0.8;
  color: white;
  font-size: 1.25rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  @media only screen and (min-width: 960px) {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}

.subtitle {
  opacity: 0.8;
  color: white;
  font-size: 1.125rem;
  line-height: 1.7rem;
  @media only screen and (min-width: 960px) {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }
}

p {
  font-size: 1rem;
  line-height: 1.875rem;
  margin: 1rem 0;
  @media only screen and (min-width: 960px) {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

.text-bold {
  @extend p;
  font-weight: 600;
}

.text-small {
  font-size: 1rem;
  line-height: 1.8rem;
}

.text-yellow {
  color: $MainColor4;
}

.text-pink {
  color: $MainColor2;
}

.text-white {
  color: white;
}

.text-light_Purple {
  color: $MainColor3Light;
}

.text-blue {
  color: $MainColor3;
}
