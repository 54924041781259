// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    #pageContainer {
        padding: 0 1vw;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    #pageContainer {
        padding: 0 2.5vw;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    #pageContainer {
        padding: 0 5vw;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    #pageContainer {
        padding: 0 7vw;
    }
}