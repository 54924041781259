.program {
  &_pic {
    &_deko {
      &_start {
        width: 40%;
        height: auto;
      }
      &_emoji {
        margin-top: 20%;
        margin-left: 20%;
        width: 40%;
        height: auto;
      }
      &_desktop {
        margin-top: 10%;
        height: auto;
        @media only screen and (max-width: 600px) {
          width: 50%;
        }
        @media only screen and (min-width: 600px) {
          width: 80%;
        }
      }
    }
    &_date {
      width: 100%;
      height: auto;
    }
    &_flex {
      display: flex;
      flex-direction: column;
    }
  }
}
.program_margin > .MuiGrid-container {
  margin-bottom: 10%;
}
.program_rowspace > .timeTable {
  margin: 0px;
  width: auto;
}

.stream-wrapper {
	display: block;
	width: 100%;
	padding-bottom: 80px;
	

	video {
		display: block;
		margin: 0 auto;	
		max-height: 77vh;
	}
}
