// Main Colors
$MainColor1: rgb(240, 113, 123);
$MainColor2: rgb(255, 168, 237);
$MainColor3: rgb(59, 64, 176);
$MainColor4: rgb(247, 255, 0);
$MainColor5: rgb(95, 225, 151);
$MainColor6: rgb(79, 201, 255);
$MainColor7: rgb(86, 65, 202);

// Light Shades
$MainColor3Light: #A296E0;

// Shadow
$ColorBoxShadow: rgb(255, 155, 245);
$ColorBoxShadowPrimary: rgba(247,254,0,0.5);
$ColorBoxShadowSecondary: rgba(106,87,206,0.5);