@import 'assets/styles/variables/colors.scss';

.infoCard {
  &_container {
    //width: 100%;
    height: fit-content;
    background-color: white;
    // border: 2px solid #3b40b0;
    // box-shadow: 7px 7px #3b40b0;
    margin: 8px 42px 34px 0px;
    padding: 25px;
    flex-wrap: wrap;
    justify-content: space-between;
    &:first-of-type {
    }
  }
  &_headline {
    margin: 0;
    margin-bottom: 5pt;
  }
}

@media screen and (max-width: 600px) {
  .infoCard_container {
    width: 80vw;
  }
}
