body{
    font-family: 'Open Sans', sans-serif;
    color: $MainColor3;
    background-color: $MainColor3;
		overflow-x: hidden;
}

.styleguide-bg{
    background-color: #c0b4ff;
}

button {
    @extend p;
    @extend .text-bold;
    padding: 0.75rem 1.5rem 0.75rem;
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
    transition-property: box-shadow;
    &.primary {
        background-color: $MainColor4;
        color: $MainColor3;
        box-shadow: 0.5rem 0.5rem $ColorBoxShadowPrimary;
        &:hover, &:active {
            box-shadow: 1rem 1rem $ColorBoxShadowPrimary;
        }
    }
    &.secondary {
        background-color: #8F81DA;  
        color: white;
        box-shadow: 0.5rem 0.5rem $ColorBoxShadowSecondary;
        &:hover, &:active {
            box-shadow: 1rem 1rem $ColorBoxShadowSecondary;
        }
    }
}

.card{
    background-color: white;
    padding: 2.5rem 3rem 3.2rem;    
}

.box-shadow{
    box-shadow: 0.5rem 0.5rem $ColorBoxShadow;
    &.hover {
        transition-property: box-shadow;
        -webkit-transition: all 100ms ease-out;
        -moz-transition: all 100ms ease-out;
        -o-transition: all 100ms ease-out;
        transition: all 100ms ease-out;
        &:hover, &:active {
            box-shadow: 1rem 1rem $ColorBoxShadow;
        }
    }
}

