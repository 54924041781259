.Topic {
  width: 100%;
  //   box-sizing: content-box;
  margin: 2vw 0;

  &_spacing {
    @media screen and (max-width: 959px) {
      padding-top: 0px !important;
    }
  }

  &_image {
    margin: 15px auto;
    // display: block;
    max-height: 500px;
    max-width: calc(100% - 30px);
    height: auto;
    width: auto;
  }

  &_description {
    // flex: 1 1 0px;
    // position: center;
    color: white;

    div {
      position: center;
    }
  }
}
