.page-stage {
				iframe { border: 0;}
	.stream-wrapper {
		width: 100%;
		height: fit-content;
		height: -moz-fit-content;
		position: relative;
		padding-top: 56.25%;
		video {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}
	#stream {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.chat-wrapper {
					height: 100%
					}
	#chat {
		width: 100%;
		height: 100%;
		min-height: 400px;//padding-top: 56.25%;
	}
}
