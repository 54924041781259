@import "assets/styles/variables.scss";

//HauptBox
$timeTableHeight: 65vh;
$timeTableWidthMobile: 60vw;
$timeTableWidthDesktop: calc(41vw * 0.95);
$paddingMainBox: 30px 35px 30px 35px;
$lineAfterButtonLength: 540px;

//Liste
$spaceLine: 18px;
$timeEventDirection: row;

//Listenelemente
$spaceBetweenTimeEvent: 20px;

//TopOrder Element
$spaceBetweenDateBar: 32px;
$spaceBetweenListHeadline: 25px;

//TimeTableList
$spaceBetweenTimeTables: 60px;

.timeTableSwitcher {
	//overflow-x: hidden;
	padding-bottom: 30px;
	// margin-left: 200px;
}

.timeTable-right {
	@media only screen and (max-width: 600px) {
		transform: translateX(calc(calc(#{$timeTableWidthMobile} + 129px) * -2));
	}
	@media only screen and (min-width: 600px) {
		transform: translateX(calc(calc(#{$timeTableWidthDesktop} + 129px) * -2));
	}
}

.timeTable-mid {
	@media only screen and (max-width: 600px) {
		transform: translateX(calc(#{$timeTableWidthMobile} * -1 - 129px));
	}
	@media only screen and (min-width: 600px) {
		transform: translateX(calc(#{$timeTableWidthDesktop} * -1 - 129px));
	}
}

.timeTable-buttonList {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.timeTable-switchButton {
	display: inline-block;
	// height: 40px;
	margin-bottom: 15px;
	background-color: transparent;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	border-bottom: 2px;
	border-color: $MainColor7;
	border-style: solid;
	@media only screen and (max-width: 600px) {
		width: 33.33333333333%;
	}
	@media only screen and (min-width: 600px) {
		min-width: 120px;
	}
}
.timeTable-switchButton:focus {
	outline: 0;
}

.timeTable-buttonActive {
	border-color: $MainColor4;
}

.timeTable-lineAfterButtons {
	@media only screen and (max-width: 600px) {
		display: none;
	}
	@media only screen and (min-width: 600px) {
		width: $lineAfterButtonLength;
	}
}

.timeTableList {
	display: flex;
	flex-direction: row;
	transition: transform 0.4s;
	max-width: 100%;
	overflow-x: scroll;
	position: relative;
}

.timeTable-bar {
	background-color: $ColorBoxShadow;
	width: 80%;
	height: 6px;
}

.timeTable-date {
	// font-size: 20px;
	// color: rgb(255, 154, 246);
	//   width: 20%;
	margin: 0px;
	margin-right: $spaceBetweenDateBar;
	white-space: nowrap;
}

.timeTable-topOrder {
	// display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: $spaceBetweenListHeadline;
	@media only screen and (max-width: 600px) {
		display: none;
	}
	@media only screen and (min-width: 600px) {
		display: flex;
	}
}

.timeTable-row-time {
	// float: left;
	margin: 0px;
	margin-right: $spaceBetweenTimeEvent;
	white-space: nowrap;
}
.timeTable-row-eventName {
	margin: 0px !important;
}

.timeTable-row {
	display: flex;
	// flex-direction: $timeEventDirection;
	margin-bottom: $spaceLine;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
	@media only screen and (min-width: 600px) {
		flex-direction: row;
	}
}

.timeTable-eventList {
	list-style-type: none;
	padding-left: 0px;
}

.timeTable {
	background-color: white;
	// width: $timeTableWidth;
	min-height: $timeTableHeight;
	padding: $paddingMainBox;
	margin-right: $spaceBetweenTimeTables;
	max-width: 100%;
	overflow-x: scroll;
	@media only screen and (max-width: 600px) {
		width: $timeTableWidthMobile;
	}
	@media only screen and (min-width: 600px) {
		width: $timeTableWidthDesktop;
	}
}

.videoLink-wrapper {
	position: relative;
	top: -15px;
	display: flex;
	gap: 10%;
	padding-bottom: 50px;
}

.program-video {
	// width: 40%

	flex-grow: 1
	// width: 40%;
	// border: 2px solid white;
	// float: right;
}

.program-video + .program-row {
	padding-top: 60px;
}
