.STIC_container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	&_vertical-align {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.STIC_doubleContainer {
	height: fit-content;
	@media screen and (min-width: 960px) {
		.infoCard_container {
			margin-right: 0;
		}
	}
}

.STIC_singleContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-content: center;
	justify-content: center;
	@media only screen and (min-width: 960px) {
		margin-top: 6%;
	}
}

@media screen and (max-width: 600px) {
	.STIC_container {
		flex-direction: column;
	}
}
