@import "../../assets/styles/variables.scss";

.studentCards {
    &_container {
        display: flex;
        overflow-x: auto;
        flex-wrap: nowrap;
        justify-content: left;
        padding-bottom: 20px;
        width: calc(100vw - 16px);
        margin-left: -16px;

        @media only screen and (min-width: 600px) {
            width:100%;
            margin:0px;
            flex-wrap:wrap;
            justify-content: space-around;
        }
        @media only screen and (min-width: 960px) {
            flex-wrap:nowrap;
            padding-bottom:100px;

        }

    }

    &_arrows {
        padding-top: 20px;
        width: 100%;
        padding-bottom: 30px;
        @media (min-width: 600px) {
            display: none;
        }
    }
}

.studentCard {
    &_container {
        width: 250px;
        height: auto;
        background: white;
        display: flex;
        justify-content: left;
        flex-direction: column;
        text-decoration: none;
        z-index: 50;
        margin-left: 30px;
        @media only screen and (min-width: 600px) {
            margin:0px;
            width: 32vw;
            margin-bottom:30px;
        }
        @media only screen and (min-width: 720px) {
            width: 28vw;
        }
        @media only screen and (min-width: 960px) {
            width: 14vw;
            &:nth-child(2){
                transform: translate(0px,100px);
            }
            &:nth-child(3){
                transform: translate(0px,40px);
            }
            &:nth-child(4){
                transform: translate(0px,70px);
            }
        }
        & img {
            height: 260px;
            border: 5px solid white;
            object-fit: cover;
            @media only screen and (min-width: 960px) {
                height: 16vw;
            }
        }
        &_text {
            float: left;
            padding: 20px;
            padding-top: 10px;
            margin: 0px;
            padding-bottom: 25px;
            &_name {
                // font-family: "Louis George Café";
                // color: $MainColor3Light;
                // font-size: 20px;
                // font-weight: bold;
                // margin: 0px 0px ;
                line-height: 2.2rem;
                margin-top: 0px;
            }
            &_subline {
                font-family: "Open Sans";
                font-size: 18px;
                line-height: 22pt;
                margin: 0px;
                color: $MainColor3;
            }
            &_mail {
                font-family: "Open Sans";
                font-weight: 600;
                font-size: 18px;
                line-height: 24pt;
                color: #452ec2;
                margin: 0px;
                margin-top: 20px;
                z-index: 200;
            }
        }
    }
}
