@import "../../assets/styles/variables.scss";

.videoGallery {
    &_container {
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        &_single-vid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            .videoGallery_container_card_container {
                width: 100%;
            }
        }
        &_double-vid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            .videoGallery_container_card_container {
                @media screen and (min-width: 601px) {
                    width: calc(50% - 48px);
                }
                @media screen and (max-width: 600px) {
                    width: 100%;
                }
            }
        }
        &_card_container {
            @media screen and (min-width: 960px) {
                width: calc(33% - 48px);
            }
            @media screen and (min-width: 601px) and (max-width: 959px) {
                width: calc(50% - 48px);
            }
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
        &_video-card {
            background-color: white;
            background-size: cover;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            padding: 8px;
            width: 100%;
            position: relative;
            h4 {
                position: relative;
                margin-left:0.5rem;
                color: rgb(170, 161, 217);;
            }
            iframe {
                padding: 0px;
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }
        &_frame {
            position: relative;
            padding-bottom: 56.25%;
            width: 100%;
        }
    }
}

.videoGallery_container_card_container {
    padding: 24px;
}