@import "../../assets/styles/variables/colors.scss";

// .programm {
//   @media only screen and (max-width: 600px) {
//     background-color: $MainColor6;
//   }
// }
.programm-text-Button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.programm-desktop {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.programm-mobile {
  @media only screen and (min-width: 600px) {
    display: none;
  }
}
