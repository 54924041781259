@import "assets/styles/variables.scss";

.flip-card {
	position: relative;
	background-color: transparent;
	width: 100%;
	//height: 18vw;
	//	width: 26vw;
	//border: 1px solid #f1f1f1;
	box-sizing: border-box;
	resize: vertical;
	max-height: 100%;
	//overflow: auto;
	perspective: 2000px; /* Remove this if you don't want the 3D effect */
	button {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 0;
	}
}

.flip-card-inner {
	position: relative;
	box-shadow: 0 0 rgba(106, 87, 206, 0.5);
	//width: 100%;
	//height: 100%;
	text-align: center;
	transition: transform 0.4s, box-shadow 0.4s;
	transform-style: preserve-3d;
	background: #fff;
	padding-bottom: 75%;
}

.flipped .flip-card-inner {
	//transform: translate(-20px, -20px); //rotateY(180deg);
	box-shadow: 1rem 1rem rgba(106, 87, 206, 0.5);

	//	box-shadow: 1rem 1rem rgba(247, 254, 0, 0.5);

	.flip-card-front {
		opacity: 0;
	}
	.flip-card-back {
		opacity: 1;

		button {
			display: inline-block;
			background-color: $MainColor4;
		}
	}
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	//height: 100%;
	transition: opacity ease-out 0.4s;
	//-webkit-backface-visibility: hidden; /* Safari */
	//backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #bbb;
	background-position: center center;
	padding-bottom: 75%;
	color: black;
	img {
		width: 100%;
		height: auto;
	}
}

/* Style the back side */
.flip-card-back {
	background-color: $MainColor4;
	color: $MainColor3;
	opacity: 0;
	height: 100%;
	p {
		padding: 0 15px;
	}
	button {
		display: none;
	}
	@media only screen and (min-width: 600px) and (max-width: 960px) {
		p {
			font-size: 2.5em;
			line-height: 1.2em;
		}
	}
	//transform: rotateY(180deg);
}
